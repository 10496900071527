<template>

    <div>

        <div class="breadcrumb justify-content-end">
            <div class="d-flex  breadcrumb-wrapper pr-1">
                <b-breadcrumb-item :to="{ name: 'dashboard' }">
                    <feather-icon class="align-text-top" icon="HomeIcon" size="15"/>
                </b-breadcrumb-item>
                <b-breadcrumb-item active>{{action == 'editing'? $t('label_edit_mail') : $t('label_add_mail')}}
                </b-breadcrumb-item>
            </div>
        </div>
        <b-col cols="12" md="12">
            <b-card
                    no-body
                    class="mb-0"
            >
                <!-- Header -->
                <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
                    <h5 class="mb-0">
                        {{ action == 'editing'? $t('label_edit_mail') : $t('label_add_mail') }}
                    </h5>

                </div>


                <validation-observer
                        #default="{ handleSubmit, invalid }"
                        :ref="PREFIX + '_FORM'"
                        tag="form"
                        class="p-2"
                >

                    <validation-provider
                            #default="validationProps"
                            :name="$t('label_date_of_creating')"
                            rules="required"
                    >
                        <b-form-group
                                :label="$t('label_date_of_creating')"
                                :state="getValidationState(validationProps)"
                        >
                            <custom-date-picker
                                    :class="{'date--picker': true , 'is-invalid':getValidationState(validationProps) === false, 'is-valid':getValidationState(validationProps) === true}"
                                    :locale="$store.state.appConfig.locale"
                                    :placeholder="$t('label_DD-MM-YYYY')"
                                    :value="itemData.created_at?itemData.created_at: $moment().format('DD-MM-YYYY')"
                                    disabled
                                    @input="itemData.created_at = $event"
                            >
                                <template #clear-btn="{ vm }"><feather-icon icon="XIcon" size="14"/></template>
                                <div slot="label">
                                    <feather-icon title="Clear" data-toggle icon="CalendarIcon" size="18"/>
                                </div>
                            </custom-date-picker>
                            <b-form-invalid-feedback :state="getValidationState(validationProps)">
                                {{ validationProps.errors[0] }}
                            </b-form-invalid-feedback>
                        </b-form-group>
                    </validation-provider>
                    <validation-provider
                            #default="validationProps"
                            :name="$t('label_mail_type')"
                            rules="required"
                    >
                        <b-form-group
                                :label="$t('label_mail_type')"
                                :state="getValidationState(validationProps)"
                        >
                            <v-select
                                    v-model="itemData.mail_type"
                                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                    :options="mailTypes"
                                    :reduce="val => val.alias"
                                    label="name"
                                    :clearable="false"
                                    :placeholder="$t('label_select')"
                                    @input="processMailTypeChanging($event)"
                            >
                                <template v-slot:option="option">
                                    <span class="">{{ $t(option.trans_index) }}</span>
                                </template>
                                <template v-slot:selected-option="option">
                                    <!--<span :class="option.icon"></span>-->
                                    <span class="">{{ $t(option.trans_index) }}</span>
                                </template>
                                <template v-slot:no-options="option">
                                    {{$t('label_no_items')}}
                                </template>
                            </v-select>
                            <b-form-invalid-feedback :state="getValidationState(validationProps)">
                                {{ validationProps.errors[0] }}
                            </b-form-invalid-feedback>
                        </b-form-group>
                    </validation-provider>

                    <validation-provider v-if="itemData.mail_type && itemData.mail_type != 'undecided'"
                            #default="validationProps"
                            :name="$t('label_shipment_type')"
                            rules="required"
                    >
                        <b-form-group
                                :label="$t('label_shipment_type')"
                                :state="getValidationState(validationProps)"
                        >
                            <v-select
                                    v-model="itemData.shipment_type_id"
                                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                    :options="shipmentTypes"
                                    :reduce="val => val.id"
                                    label="name"
                                    :clearable="false"
                                    :placeholder="$t('label_select')"
                            >
                                <template v-slot:option="option">
                                    <span class="">{{ $t(option.translation_index) }}</span>
                                </template>
                                <template v-slot:selected-option="option">
                                    <!--<span :class="option.icon"></span>-->
                                    <span class="">{{ $t(option.translation_index) }}</span>
                                </template>
                                <template v-slot:no-options="option">
                                    {{$t('label_no_items')}}
                                </template>
                            </v-select>
                            <b-form-invalid-feedback :state="getValidationState(validationProps)">
                                {{ validationProps.errors[0] }}
                            </b-form-invalid-feedback>
                        </b-form-group>
                    </validation-provider>

                       <template  v-if="itemData.mail_type && itemData.mail_type != 'undecided'">
                           <validation-provider
                                   v-if="itemData.mail_type == 'incoming'"
                                   #default="validationProps"
                                   :name="$t('label_receiving_date')"
                                   rules="required"
                           >
                               <b-form-group
                                       :label="$t('label_receiving_date')"
                                       :state="getValidationState(validationProps)"
                               >
                                   <custom-date-picker
                                           :class="{'date--picker': true , 'is-invalid':getValidationState(validationProps) === false, 'is-valid':getValidationState(validationProps) === true}"
                                           :locale="$store.state.appConfig.locale"
                                           :placeholder="$t('label_DD-MM-YYYY')"
                                           :value="itemData.receiving_at"

                                           @input="itemData.receiving_at = $event"
                                   >
                                       <template #clear-btn="{ vm }"><feather-icon icon="XIcon" size="14"/></template>
                                       <div slot="label">
                                           <feather-icon title="Clear" data-toggle icon="CalendarIcon" size="18"/>
                                       </div>
                                   </custom-date-picker>
                                   <b-form-invalid-feedback :state="getValidationState(validationProps)">
                                       {{ validationProps.errors[0] }}
                                   </b-form-invalid-feedback>
                               </b-form-group>
                           </validation-provider>
                           <validation-provider
                                   v-if="itemData.mail_type == 'outcoming'"
                                   #default="validationProps"
                                   :name="$t('label_sending_date')"
                                   rules="required"
                           >
                               <b-form-group
                                       :label="$t('label_sending_date')"
                                       :state="getValidationState(validationProps)"
                               >
                                   <custom-date-picker
                                           :class="{'date--picker': true , 'is-invalid':getValidationState(validationProps) === false, 'is-valid':getValidationState(validationProps) === true}"
                                           :locale="$store.state.appConfig.locale"
                                           :placeholder="$t('label_DD-MM-YYYY')"
                                           :value="itemData.receiving_at"

                                           @input="itemData.receiving_at = $event"
                                   >
                                       <template #clear-btn="{ vm }"><feather-icon icon="XIcon" size="14"/></template>
                                       <div slot="label">
                                           <feather-icon title="Clear" data-toggle icon="CalendarIcon" size="18"/>
                                       </div>
                                   </custom-date-picker>
                                   <b-form-invalid-feedback :state="getValidationState(validationProps)">
                                       {{ validationProps.errors[0] }}
                                   </b-form-invalid-feedback>
                               </b-form-group>
                           </validation-provider>
                       </template>



                    <validation-provider
                            #default="validationProps"
                            :name="$t('label_addressee')"
                            slim
                    >
                        <b-form-group
                                label=""
                                label-for="meeting_type_id"
                        >
                            <!--:checked="itemData.google_sync"-->
                            <b-form-checkbox
                                    id="mail-adresse"
                                    switch
                                    size="md"
                                    value="1"
                                    unchecked-value="0"
                                    v-model="itemData.with_recipient"

                                    @change="($event) => {if(!$event){clearParticipant('recipient')}}"
                            >
                                <label for="mail-adresse">{{ $t('label_addressee') }}</label>
                            </b-form-checkbox>
                        </b-form-group>
                    </validation-provider>

                    <div id="recipient-block" v-if="itemData.with_recipient == 1">

                        <b-row>

                            <b-col cols="12" md="12">

                                <b-row>
                                    <b-col cols="12" md="6">
                                        <validation-provider
                                                #default="validationProps"
                                                :name="$t('label_search_from_recipients_or_add_a_new_one')"
                                                rules=""
                                        >
                                            <b-form-group
                                                    :label="$t('label_search_from_recipients_or_add_a_new_one')"

                                                    :state="getValidationState(validationProps)"
                                            >
                                                <!--:reduce="val => val.id"-->
                                                <!--selected-prop="deal_id"-->
                                                <infinity-scroll
                                                        :query-params="{participant_type:'recipient'}"
                                                        :default-selection="chosenRecipient"

                                                        :multiple="false"
                                                        url="/mail_participants"
                                                        :placeholder="$t('label_select')"
                                                        @input="fillParticipant($event, 'recipient')"
                                                >
                                                    <template #label="{item}">{{ item.name }}</template>

                                                </infinity-scroll>

                                                <b-form-invalid-feedback :state="getValidationState(validationProps)">
                                                    {{ validationProps.errors[0] }}
                                                </b-form-invalid-feedback>
                                            </b-form-group>
                                        </validation-provider>
                                    </b-col>
                                </b-row>

                            </b-col>
                            <div></div>
                            <b-col cols="12" md="6">

                                <validation-provider
                                        #default="validationProps"
                                        :name="$t('label_name_of_addressee')"
                                        slim
                                        rules="required"
                                        vid="recipient_name"

                                >
                                    <b-form-group
                                            :label="$t('label_name_of_addressee')"

                                    >
                                        <!--:checked="itemData.google_sync"-->
                                        <b-form-input
                                                v-model="itemData.recipient_name"
                                                :state="getValidationState(validationProps)"
                                                trim
                                                :placeholder="$t('label_name_of_addressee')"
                                        />
                                        <b-form-invalid-feedback>
                                            {{ validationProps.errors[0] }}
                                        </b-form-invalid-feedback>
                                    </b-form-group>
                                </validation-provider>
                            </b-col>

                            <b-col cols="12" md="6">

                                <validation-provider
                                        #default="validationProps"
                                        :name="$t('label_address')"
                                        slim
                                        rules="required"
                                        vid="recipient_address"
                                >
                                    <b-form-group
                                            :label="$t('label_address')"
                                    >
                                        <b-form-input

                                                v-model="itemData.recipient_address"
                                                :state="getValidationState(validationProps)"
                                                trim
                                                :placeholder="$t('label_address')"
                                        />
                                        <b-form-invalid-feedback>
                                            {{ validationProps.errors[0] }}
                                        </b-form-invalid-feedback>
                                    </b-form-group>
                                </validation-provider>
                            </b-col>

                            <b-col cols="12">


                                <b-form-group
                                        class="d-flex justify-content-end"
                                >

                                    <button @click.prevent="addParticipant('recipient')" class="btn btn-warning  pull-right"
                                    >{{$t('label_add_to_recipient_database')}}
                                    </button>

                                </b-form-group>

                            </b-col>


                        </b-row>

                    </div>

                    <validation-provider
                            #default="validationProps"
                            :name="$t('label_sender')"
                            slim
                    >
                        <b-form-group
                                label=""
                        >
                            <!--:checked="itemData.google_sync"-->
                            <b-form-checkbox
                                    id="mail-sender"
                                    switch
                                    size="md"
                                    value="1"
                                    unchecked-value="0"
                                    v-model="itemData.with_sender"

                                    @change="($event) => {if(!$event){clearParticipant('sender')}}"
                            >
                                <label for="mail-sender">{{ $t('label_sender') }}</label>
                            </b-form-checkbox>
                        </b-form-group>
                    </validation-provider>

                    <div id="sender-block" v-if="itemData.with_sender == 1">


                        <b-row>


                            <b-col cols="12" md="12">

                                <b-row>
                                    <b-col cols="12" md="6">
                                        <validation-provider
                                                #default="validationProps"
                                                :name="$t('label_search_from_recipients_or_add_a_new_one')"
                                                rules=""
                                        >
                                            <b-form-group
                                                    :label="$t('label_search_from_senders_or_add_a_new_one')"

                                                    :state="getValidationState(validationProps)"
                                            >
                                                <!--:reduce="val => val.id"-->
                                                <infinity-scroll
                                                        :default-selection="chosenSender"
                                                        :query-params="{participant_type:'sender'}"

                                                        :multiple="false"
                                                        url="/mail_participants"
                                                        :placeholder="$t('label_select')"

                                                        @input="fillParticipant($event, 'sender')"

                                                >
                                                    <template #label="{item}">{{ item.name }}</template>

                                                </infinity-scroll>

                                                <b-form-invalid-feedback :state="getValidationState(validationProps)">
                                                    {{ validationProps.errors[0] }}
                                                </b-form-invalid-feedback>
                                            </b-form-group>
                                        </validation-provider>
                                    </b-col>
                                </b-row>

                            </b-col>

                            <b-col cols="12" md="6">

                                <validation-provider
                                        #default="validationProps"
                                        :name="$t('label_sender_name')"
                                        slim
                                        rules="required"
                                        vid="sender_name"
                                >
                                    <b-form-group
                                            :label="$t('label_sender_name')"

                                    >
                                        <!--:checked="itemData.google_sync"-->
                                        <b-form-input
                                                v-model="itemData.sender_name"
                                                :state="getValidationState(validationProps)"
                                                trim
                                                :placeholder="$t('label_name_of_addressee')"
                                        />
                                        <b-form-invalid-feedback>
                                            {{ validationProps.errors[0] }}
                                        </b-form-invalid-feedback>
                                    </b-form-group>
                                </validation-provider>
                            </b-col>

                            <b-col cols="12" md="6">

                                <validation-provider
                                        #default="validationProps"
                                        :name="$t('label_address')"
                                        slim
                                        rules="required"
                                        vid="sender_address"
                                >
                                    <b-form-group
                                            :label="$t('label_address')"
                                    >
                                        <b-form-input

                                                v-model="itemData.sender_address"
                                                :state="getValidationState(validationProps)"
                                                trim
                                                :placeholder="$t('label_address')"
                                        />
                                        <b-form-invalid-feedback>
                                            {{ validationProps.errors[0] }}
                                        </b-form-invalid-feedback>
                                    </b-form-group>
                                </validation-provider>
                            </b-col>

                            <b-col cols="12">


                                <b-form-group
                                        class="d-flex justify-content-end"
                                >

                                    <button @click.prevent="addParticipant('sender')" class="btn btn-warning  pull-right"
                                    >{{$t('label_add_to_senders_database')}}
                                    </button>

                                </b-form-group>

                            </b-col>


                        </b-row>


                    </div>

                    <b-row>
                        <b-col cols="12" >
                            <validation-provider
                                    #default="validationProps"
                                    :name="$t('label_deadline_reply')"
                                    slim
                            >
                                <b-form-group
                                        label=""
                                        label-for="meeting_type_id"
                                >
                                    <!--:checked="itemData.google_sync"-->
                                    <b-form-checkbox
                                            id="reply-deadline"

                                            size="md"
                                            value="1"
                                            unchecked-value="0"
                                            v-model="deadline_reply"

                                            @change="($event) => {if(!$event){clearParticipant('recipient')}}"
                                    >
                                        <label for="reply-deadline">{{ $t('label_deadline_reply') }}</label>
                                    </b-form-checkbox>
                                </b-form-group>
                            </validation-provider>
                        </b-col>
                        <b-col cols="12"  v-if="deadline_reply == 1">
                            <validation-provider
                                    #default="validationProps"
                                    :name="$t('label_term')"
                                    rules="required"
                            >
                                <b-form-group
                                        :label="$t('label_term')"
                                        :state="getValidationState(validationProps)"
                                >
                                    <custom-date-picker
                                            :class="{'date--picker': true , 'is-invalid':getValidationState(validationProps) === false, 'is-valid':getValidationState(validationProps) === true}"
                                            :locale="$store.state.appConfig.locale"
                                            :placeholder="$t('label_DD-MM-YYYY')"
                                            :value="itemData.deadline_reply_at"

                                            @input="itemData.deadline_reply_at = $event"
                                    >
                                        <template #clear-btn="{ vm }"><feather-icon icon="XIcon" size="14"/></template>
                                        <div slot="label">
                                            <feather-icon title="Clear" data-toggle icon="CalendarIcon" size="18"/>
                                        </div>
                                    </custom-date-picker>
                                    <b-form-invalid-feedback :state="getValidationState(validationProps)">
                                        {{ validationProps.errors[0] }}
                                    </b-form-invalid-feedback>
                                </b-form-group>
                            </validation-provider>
                        </b-col>
                    </b-row>
                    <b-row>


                        <b-col cols="12" md="6">

                            <validation-provider
                                    #default="validationProps"
                                    :name="$t('label_postal_operator')"
                                    slim
                                    rules="required"

                            >
                                <b-form-group
                                        :label="$t('label_postal_operator')"

                                >
                                    <!--:checked="itemData.google_sync"-->
                                    <b-form-input
                                            v-model="itemData.postal_operator"
                                            :state="getValidationState(validationProps)"
                                            trim
                                            :placeholder="$t('label_postal_operator')"
                                    />
                                    <b-form-invalid-feedback>
                                        {{ validationProps.errors[0] }}
                                    </b-form-invalid-feedback>
                                </b-form-group>
                            </validation-provider>
                        </b-col>

                        <b-col cols="12" md="6">

                            <validation-provider
                                    #default="validationProps"
                                    :name="$t('label_tracking_number')"
                                    slim
                                    rules="required"
                            >
                                <b-form-group
                                        :label="$t('label_tracking_number')"
                                >
                                    <b-form-input

                                            v-model="itemData.tracking_number"
                                            :state="getValidationState(validationProps)"
                                            trim
                                            :placeholder="$t('label_tracking_number')"
                                    />
                                    <b-form-invalid-feedback>
                                        {{ validationProps.errors[0] }}
                                    </b-form-invalid-feedback>
                                </b-form-group>
                            </validation-provider>
                        </b-col>


                    </b-row>

                    <b-row>


                        <b-col cols="12" md="6">

                            <validation-provider
                                    #default="validationProps"
                                    :name="$t('label_agreement')+ ' / ' + $t('label_deal')"
                                    :rules="requireExtraFields? 'required' : ''"
                            >
                                <b-form-group
                                        :label="$t('label_agreement')+ ' / ' + $t('label_deal')"

                                        :state="getValidationState(validationProps)"
                                >
                                    <infinity-scroll

                                            selected-prop="id"
                                            :multiple="false"
                                            url="/agreement"
                                            :placeholder="$t('label_select')"

                                            v-model="itemData.agreement_id"
                                            :default-selection="chosenAgreement"

                                    >
                                        <!--@input="checkSmsSending($event)"-->
                                        <template #label="{item}">{{ item.deal_number }}</template>

                                    </infinity-scroll>

                                    <b-form-invalid-feedback :state="getValidationState(validationProps)">
                                        {{ validationProps.errors[0] }}
                                    </b-form-invalid-feedback>
                                </b-form-group>
                            </validation-provider>
                        </b-col>

                        <b-col cols="12" md="6">

                            <validation-provider
                                    #default="validationProps"
                                    :name="$t('label_responsible_person')"
                                    :rules="requireExtraFields? 'required' : ''"
                            >
                                <b-form-group
                                        :label="$t('label_responsible_person')"

                                        :state="getValidationState(validationProps)"
                                >

                                    <infinity-scroll

                                            selected-prop="id"
                                            :multiple="false"
                                            url="/user"
                                            :placeholder="$t('label_select')"

                                            v-model="itemData.responsible_user_id"
                                            :default-selection="chosenRespUser"

                                    >
                                        <template #label="{item}">{{ item.name }}</template>

                                    </infinity-scroll>

                                    <b-form-invalid-feedback :state="getValidationState(validationProps)">
                                        {{ validationProps.errors[0] }}
                                    </b-form-invalid-feedback>
                                </b-form-group>
                            </validation-provider>

                        </b-col>


                    </b-row>


                    <validation-provider
                            #default="validationProps"
                            :name="$t('label_send') + ' ' + $t('label_email_to_respons_user_desc')"
                            slim
                    >
                        <b-form-group
                                label=""
                        >
                            <b-form-checkbox
                                    :disabled="!itemData.responsible_user_id"
                                    class="mt-1"
                                    id="notify-users"
                                    value="1"
                                    unchecked-value="0"
                                    size="md"
                                    v-model="itemData.notify_users"
                            >
                                <label for="notify-users">{{ $t('label_send') + ' ' + $t('label_email_to_respons_user_desc') }}</label>
                            </b-form-checkbox>
                        </b-form-group>
                    </validation-provider>

                    <validation-provider
                            #default="validationProps"
                            :name="$t('label_send') + ' ' + $t('label_notification_to_client_desc')"
                            slim
                    >
                        <b-form-group
                                label=""
                        >
                            <!--:checked="itemData.google_sync"-->
                            <b-form-checkbox
                                    id="notify-client"

                                    value="1"
                                    unchecked-value="0"
                                    size="md"
                                    v-model="itemData.notify_client"
                            >
                                <label for="notify-client">{{ $t('label_send') + ' ' + $t('label_notification_to_client_desc') }}</label>
                            </b-form-checkbox>
                        </b-form-group>
                    </validation-provider>

                    <!--<validation-provider-->
                            <!--class="mt-2"-->
                            <!--#default="validationProps"-->
                            <!--:name="$t('label_send_sms')"-->
                            <!--slim-->
                    <!--&gt;-->
                        <!--<b-form-group-->
                                <!--label=""-->
                        <!--&gt;-->
                            <!--<b-form-checkbox-->
                                    <!--:disabled="itemData.agreement_id <= 0"-->
                                    <!--id="notify_via_sms"-->
                                    <!--value="1"-->
                                    <!--unchecked-value="0"-->
                                    <!--v-model="itemData.notify_via_sms"-->
                            <!--&gt;-->
                                <!--<label for="notify_via_sms">{{ $t('label_send_sms') }}</label>-->
                            <!--</b-form-checkbox>-->
                        <!--</b-form-group>-->
                    <!--</validation-provider>-->


                    <validation-provider
                            #default="validationProps"
                            :name="$t('label_file_name')"
                            slim
                            rules=""
                    >
                        <b-form-group
                                :label="$t('label_file_name')"
                        >
                            <b-form-input

                                    v-model="itemData.file_name"
                                    :state="getValidationState(validationProps)"
                                    trim
                                    :placeholder="$t('label_file_name')"
                            />
                            <b-form-invalid-feedback>
                                {{ validationProps.errors[0] }}
                            </b-form-invalid-feedback>
                        </b-form-group>
                    </validation-provider>

                    <validation-provider

                            #default="validationProps"
                            :name="$t('label_description')"
                            rules=""
                            slim
                    >
                        <b-form-group
                                :label="$t('label_description')"

                        >
                            <b-form-textarea
                                    :placeholder="$t('label_description')"
                                    :state="getValidationState(validationProps)"
                                    v-model="itemData.description"
                            ></b-form-textarea>

                            <b-form-invalid-feedback :state="getValidationState(validationProps)">
                                {{ validationProps.errors[0] }}
                            </b-form-invalid-feedback>
                        </b-form-group>
                    </validation-provider>

                  <label
                      for="share_to_role"
                      class="w-100"
                  >
                    <b-form-checkbox
                        v-model="selectedAll"
                        inline
                        name="event-filter-all"
                        class="mb-1"
                        :class="`custom-control-primary`"
                        @change="selectAllRoles"
                    >
                      {{ $t('label_share_to_role') }}
                    </b-form-checkbox>
                  </label>
                  <div>
                    <b-form-checkbox-group
                        id="share_to_role"
                        v-model="itemData.share_to_role"
                        name="share_to_role"
                        stacked
                        :label="$t('label_share_to_role')"
                    >
                      <b-form-checkbox
                          v-for="item in roleItems"
                          :key="item.role"
                          :disabled="item.disabled"
                          name="event-filter"
                          :value="getRole(item.role)"
                          class="mb-1 col-sm-6"
                          :class="`custom-control-primary`"
                      >
                        {{ $t('label_' + item.role) }}
                      </b-form-checkbox>
                    </b-form-checkbox-group>
                  </div>

                    <validation-provider
                            class="mt-2"
                            #default="validationProps"
                            :name="$t('label_to_ocr')"
                            slim
                    >
                        <b-form-group
                                label=""
                        >
                            <!--:checked="itemData.google_sync"-->
                            <b-form-checkbox
                                    id="mail-ocr"
                                    value="1"
                                    unchecked-value="0"
                                    v-model="ocr"
                            >
                                <label for="mail-ocr">{{ $t('label_to_ocr') }}</label>
                            </b-form-checkbox>
                        </b-form-group>
                    </validation-provider>

                    <validation-provider
                            class="mt-2"
                            :name="$t('label_document')"
                            slim
                            #default="validationProps"
                            rules=""
                    >
                        <file-input
                                v-model="document"
                                class="file--uploader size--big drop-block "
                                :max-files-count='1'
                                :multiple="false"
                                :allowed-extensions="false"
                                :max-file-size='100'
                                :url="''"
                        >
                            <template #uploadBody>
                                <feather-icon class="mr-1" data-toggle icon="DownloadCloudIcon" size="24"/>
                                <!--<img :src="require('@/assets/images/upload.svg')" class="mr-1">-->
                                <span>{{$t('label_add_file')}}</span>
                            </template>

                            <template #errorBug="fileErrors">

                                <span v-if="validationProps.failedRules.length > 0 || fileErrors.errors.length > 0">{{validationProps.failedRules.length  > 0 ?  validationProps.errors[0] : fileErrors.errors[0] }}</span>

                            </template>
                        </file-input>
                        <b-form-invalid-feedback :state="validationProps.failedRules.length > 0">
                            {{ validationProps.errors[0] }}
                        </b-form-invalid-feedback>

                    </validation-provider>
                    <div style="width: 95%;min-height: 500px;margin: 30px auto 30px 0;position:relative"
                         v-if="document.length || alreadySavedDoc">
                        <div><span class="text-success mb-1 d-block">{{alreadySavedDoc ? alreadySavedDoc.display_name : document[0].name}}</span>
                            <feather-icon class="cursor-pointer" style="position:absolute; right:4px; top:0" color="red"
                                          @click="alreadySavedDoc? deleteDocFromServer(alreadySavedDoc.id) : document = []" icon="Trash2Icon"></feather-icon>
                        </div>
                        <iframe style="width:100%;height:100%;min-height: 500px" :src="frameSrc" ref="pdf-previewer"
                                name="pdf-previewer"></iframe>
                    </div>
                    <!-- Form Actions -->
                    <div class="d-flex mt-2 justify-content-end">

                        <b-button
                                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                                type="button"
                                variant="outline-secondary"
                                class="mr-2"
                                @click="$router.go(-1);"
                        >
                            {{ $t('label_cancel') }}

                        </b-button>
                        <b-button @click.prevent="handleSubmit(onSubmit);focusOnFormError(invalid)"
                                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                                  variant="primary"

                                  type="submit"
                        >
                            {{ action == 'editing'? $t('label_submit') : $t('label_submit') }}
                        </b-button>
                    </div>

                </validation-observer>

            </b-card>
        </b-col>

    </div>

</template>


<script>
    import {
        BSidebar, BForm, BFormGroup, BFormInput, BFormInvalidFeedback, BButton, BCard, BFormTextarea, BFormCheckbox, BFormCheckboxGroup
    } from 'bootstrap-vue'
    import fileInput from '@/views/components/fileUploadInput'
    import {ValidationProvider, ValidationObserver} from 'vee-validate'


    import vSelect from 'vue-select'
    import infinityScroll from '@/views/components/infinityScroll'
    import {serialize} from 'object-to-formdata';
    import { MAIL_PREFIX as PREFIX, PARTICIPANT_PREFIX, mailTypes} from './moduleHelper'

    export default {
        components: {
          BFormCheckboxGroup,
            BSidebar,
            BForm,
            BFormGroup,
            BFormInput,
            BFormInvalidFeedback,
            BButton, BCard,
            vSelect,

            BFormCheckbox,
            BFormTextarea,
            // Form Validation
            ValidationProvider,
            ValidationObserver,
            fileInput,
            infinityScroll,

        },

        props: [],

        data() {
            return {
                PARTICIPANT_PREFIX,
                PREFIX,
                mailTypes,

                action: 'adding',
                userData: JSON.parse(localStorage.getItem('userData')),
                roleItems: [],

                frameSrc: '',
                itemData: {},

                blankItemData: {
                    mail_type: '',
                    with_recipient: 0,
                    recipient_address: null,
                    recipient_name: null,
                    with_sender: 0,
                    sender_address: null,
                    sender_name: null,
                    postal_operator: '',
                    tracking_number: '',
                    agreement_id: null,
                    created_at: '',
                    deadline_reply_at: '',
                    responsible_user_id: '',
                    receiving_at:'',
                    notify_users: 0,
                    notify_client: 0,
                    description: '',
                    file_name: '',
                    shipment_type_id: null,
                  share_to_role: [],
                    // notify_via_sms: 0
                },

                deadline_reply: 0,
                document: [],
                ocr: false,

                chosenRecipient: false,
                chosenSender: false,
                alreadySavedDoc:false,
                requireExtraFields: false,

                chosenRespUser: false,
                chosenAgreement: false,
                shipmentTypes: [],
              selectedAll: false,
            }
        },

      computed: {
        defaultRoles() {
          return this.roleItems.map(role => (role.default ? this.getRole(role.role, true) : '')).filter(Boolean)
        },
        disabledRoles() {
          return this.roleItems.map(role => (!role.disabled ? this.getRole(role.role, true) : '')).filter(Boolean)
        },
      },
        watch: {
          'itemData.share_to_role': {
            deep: true,
            handler(newValue) {
              if (this.disabledRoles.length === newValue?.length) this.selectedAll = true
              else this.selectedAll = false
            },
          },
            document: function (newVal, old) {
                if (newVal.length > 0) {
                    let mimeType = newVal[0].base64.match(/[^:]\w+\/[\w-+\d.]+(?=;|,)/)[0];
                    this.showInFrame(newVal[0].base64, mimeType);
                }
            },
            deadline_reply: function(newVal){
                if(newVal == 0) {
                    this.itemData.deadline_reply_at = '';
                }
            }
        },
      async mounted() {
        await this.getPageRoles()
      },
        methods: {
          selectAllRoles(value) {
            if (value) this.itemData.share_to_role = this.disabledRoles
            else this.itemData.share_to_role = []
          },
          async getPageRoles(){
            this.roleItems = this.getSystemRoles(this.userData?.role)
          },
            onSubmit() {


                let formData = new FormData();
                formData = serialize(this.itemData, {}, formData);

                if (this.document.length > 0) {
                    for (let i = 0; i < this.document.length; i++) {
                        formData.append('document[]', this.document[i]);
                    }
                }

                if (this.action == 'adding') {
                    this.async('post', this.PREFIX, formData, function (resp) { //this.itemData.contact_id

                        this.$router.push({name: 'mails_' + this.itemData.mail_type});
                    }, false, {headers: {'Content-Type': 'multipart/form-data'}});

                } else {
                    this.async('put', this.PREFIX + '/' + this.itemData.id, formData, function (resp) {

                        this.$router.push({name: 'mails_' + this.itemData.mail_type});
                    }, false, {headers: {'Content-Type': 'multipart/form-data'}});

                }
            },


            fillParticipant(item, type) {

                if (item) {

                    this.itemData[type + '_name'] = item.name;
                    this.itemData[type + '_address'] = item.address;
                } else {
                    this.clearParticipant(type);
                }

            },
            clearParticipant(type){

                this.itemData[type + '_name'] = null;
                this.itemData[type + '_address'] = null;
                this.$refs[this.PREFIX + '_FORM'].reset();
            },

            addParticipant(type) {
                let item = {};
                item.type = type;
                item.name = this.itemData[type+'_name'];
                item.address = this.itemData[type+'_address'];
                this.async('post', '/' + this.PREFIX + '_' + this.PARTICIPANT_PREFIX + 's', item, function (resp) {
                    this['chosen'+type.ucFirst()] = resp.data.item;
                });
            },

            showInFrame(fileUrl, mime = 'application/pdf') {
                let formData = new FormData();
                if (this.document.length > 0) {
                    for(let i = 0; i< this.document.length; i++){
                        formData.append('document[]', this.document[i]);
                    }
                }
                formData.append('ocr', this.ocr);
                formData.append('custom_ocr', 1);

                this.async('post', '/upload', formData, function(res){
                    let file = res.data.items[0];
                    if(!file) return;
                    let url = file.link;
                    this.showByUrl(url, file.mime);

                }, false,  {headers:{'Content-Type': 'multipart/form-data'}});

            },
            showByUrl(fileUrl, mime = 'application/pdf'){

                if (mime == 'application/pdf'
                    || mime == 'image/jpeg'
                    || mime == 'image/gif'
                    || mime == 'image/png') {
                    this.frameSrc = fileUrl;
                } else {
                    this.frameSrc = "https://docs.google.com/viewer?url=" + fileUrl + "&embedded=true&chrome=true&a=gt";
                }
            },
            deleteDocFromServer(id){
                this.confirmDeleting((result) => {

                });
            },
            processMailTypeChanging(mailType){
                if(mailType == 'incoming' || mailType == 'outcoming'){
                    this.requireExtraFields = true;
                } else {
                    this.requireExtraFields = false;
                }
            },

            // checkSmsSending(deal){
            //     if(!deal){
            //         this.itemData.notify_via_sms = 0;
            //     }
            // }
        },

        async created() {
          await this.getPageRoles()

            let prevRouter = sessionStorage.getItem('previousRouter');
            if(prevRouter){
                let mailType = prevRouter.substring(prevRouter.lastIndexOf('/') + 1);
                if(mailTypes.findWhere('alias', mailType)){
                    this.blankItemData.mail_type = mailType;
                }
            }

            let item_id = this.$router.currentRoute.params.id;

                this.async('get', '/select_options', {params:{options:['mail_shipment_types']}}, function(res){
                    this.shipmentTypes = res.data.mail_shipment_types;
                });

            if (item_id) {
                this.action = 'editing';
                this.async('get', '/' + this.PREFIX + '/' + item_id, {}, function (resp) {
                    let editedItem = resp.data;

                    let item = Object.assign({}, this.blankItemData);
                    item.id = editedItem.id;
                    for (let prop in item) {
                        if (editedItem.hasOwnProperty(prop)) {
                            item[prop] = editedItem[prop];
                        }
                    }

                    if(editedItem.agreement){

                        this.chosenAgreement = editedItem.agreement;
                    }
                    if(editedItem.responsible_user){
                        this.chosenRespUser = editedItem.responsible_user;
                    }
                    if(editedItem.deadline_reply_at){
                        this.deadline_reply = 1;
                    }
                    item.created_at = formatDate(editedItem.created_at, 'DD/MM/YYYY', 'unix');
                    item.deadline_reply_at = editedItem.deadline_reply_at?formatDate(editedItem.deadline_reply_at, 'DD/MM/YYYY', 'unix') : '';
                    item.receiving_at = editedItem.receiving_at? formatDate(editedItem.receiving_at, 'DD/MM/YYYY', 'unix') : '';
                    item.share_to_role = (typeof item.share_to_role === 'string' ? item.share_to_role?.split(',').filter(Boolean) : item.share_to_role) ?? []

                    this.itemData = item;
                    if(resp.data.documents && resp.data.documents.length > 0) {
                        this.alreadySavedDoc = resp.data.documents[0];
                       for(let i =0; i< resp.data.documents.length; i++){
                           // alert(this.$domain + resp.data.documents[i].dir + resp.data.documents[i].file_name);
                           this.showByUrl(this.$domain + resp.data.documents[i].dir + resp.data.documents[i].file_name, resp.data.documents[i].document_mime);
                       }
                    }
                });
            } else {
                this.action = 'adding';
                this.itemData = Object.assign({}, this.blankItemData)
              this.itemData.share_to_role = this.roleItems.map(role => (role.default ? this.getRole(role.role, true) : '')).filter(Boolean)

            }

        },


    }
</script>

